import React, { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
        const data = {
            "mode": "url",
            "model": "AOM3A1B_orangemixs.safetensors",
            "tiling": false,
            "batch_size": 1,
            "prompt": prompt,
            "negative_prompt": "worst quality, lowres",
            "seed": 1234,
            "scheduler": "Euler a",
            "n_iter": 1,
            "steps": 30,
            "cfg": 11.0,
            "offset_noise": 0.0,
            "width": 1024,
            "height": 1024,
            "clip_skip": 1,
            "loras": [
                {
                    "name": "",
                    "strength": 1.0
                }
            ],
            "embeddings": [
                {
                    "name": "",
                    "strength": 1.0
                }
            ],
            "vae": "vae-ft-mse-840000-ema-pruned.ckpt",
            "restore_faces": false,
            "fr_model": "CodeFormer",
            "codeformer_weight": 0.5,
            "enable_hr": false,
            "denoising_strength": 0.75,
            "hr_scale": 2,
            "hr_upscale": "None",
            "img2img_ref_img_type": "piece",
            "img2img_resize_mode": 0,
            "img2img_denoising_strength": 0.75,
            "controlnet_enabled": false,
            "controlnet_ref_img_type": "piece",
            "controlnet_guessmode": false,
            "controlnet_module": "canny",
            "controlnet_model": "control_v11p_sd15_softedge",
            "controlnet_weight": 1,
            "controlnet_guidance_start": 0,
            "controlnet_guidance_end": 1,
            "controlnet_ref_img_url": "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png",
            "controlnet_mask": [],
            "controlnet_resize_mode": "Scale to Fit (Inner Fit)",
            "controlnet_lowvram": false,
            "controlnet_processor_res": 512,
            "controlnet_threshold_a": 100,
            "controlnet_threshold_b": 200
          };

      try {
        const result = await axios.post('your-api-url', data);
        setResponse(result.data);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="App">
      {response && <div>{JSON.stringify(response)}</div>}
    </div>
  );
}
export default App;
